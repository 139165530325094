import getServiceBySiteIdPaginatedForCustom from "../../queries/getServiceBySiteIdPaginatedForCustom";
import getServiceBySiteIdPaginatedWithtag from "./getServiceBySiteIdPaginatedWithtag"; 
function getModifiedServices(services) {

	let resp = {};

	for (let i = 0; i < services.length; i++) {

		if(services[i].serviceStatus == false ) {
			continue;
		}

		resp[services[i].slugName] = services[i]
	}
	return resp;
}


function getAddons(modifiedServices, addons, serviceType ) {

	let resp = [], service={};

	for(let i=0; i < addons.length; i++) {
		addons[i].items =  [];

		for(let j=0; j < addons[i].itemIds.length; j++) {

			service = modifiedServices[addons[i].itemIds[j]];

			if(service){
				if (service.serviceRates && service.serviceRates.length > 0) {
					for (let k = 0; k < service.serviceRates.length; k++) {
					if (service.serviceRates[k].serviceType == serviceType && service.serviceRates[k].status) {
						service.id = `${service.siteId}::${service.slugName}`
						service.serviceRates = [service.serviceRates[k]];
						addons[i].items.push(service);
					}
					}
				}
			} // service closed

		} // addon ids closed
		
	} // Addons closed

	return addons
}

function getServicesByServiceType(args, data){
    // return new Promise(async(resolve, reject) => {

    try {
    // console.log("In getServicesByServiceType ::", args.serviceType)

	// return
	let resp = [], helperObj = {},index, modifiedServices= null;

    const services = JSON.parse(JSON.stringify(data));

    
	for (let i = 0; i < services.length; i++) {

			

		if(services[i].serviceStatus == false ) {
			continue;
		  }

        services[i].siteId = args.siteId
		services[i].id = `${services[i].siteId}::${services[i].slugName}`
	
		if (!services[i].masterProdId && !helperObj[services[i].slugName]) {
			// If it is not variation
			if (services[i].serviceRates != undefined && services[i].serviceRates.length > 0) {
				for (let j = 0; j < services[i].serviceRates.length; j++) {
					if (services[i].serviceRates[j].serviceType == args.serviceType && services[i].serviceRates[j].status) {
						// services[i].id = `${services[i].siteId}::${services[i].slugName}`
						services[i].serviceRates = [services[i].serviceRates[j]];

						// Check it has Addons
						if(services[i].addons && services[i].addons.length > 0 ) {

							if(modifiedServices == null ) {
								modifiedServices = getModifiedServices(services);
							}

							services[i].addons = getAddons(modifiedServices, services[i].addons, args.serviceType)
						}

						resp.push(Object.assign({id:`${services[i].siteId}::${services[i].slugName}`}, services[i]));
						helperObj[services[i].slugName] = resp.length;
					}
				}
			}
		} else {
			// If it is variation
			// console.log("In else", services[i].slugName)
			if (services[i].serviceRates != undefined && services[i].serviceRates.length > 0) {
				for (let j = 0; j < services[i].serviceRates.length; j++) {
					if (services[i].serviceRates[j].serviceType == args.serviceType && services[i].serviceRates[j].status) {

						// services[i].id = `${services[i].siteId}::${services[i].slugName}`
						services[i].serviceRates = [services[i].serviceRates[j]];

						// Check it has Addons
						if(services[i].addons && services[i].addons.length > 0 ) {

							if(modifiedServices == null ) {
								modifiedServices = getModifiedServices(services);
							}

							services[i].addons = getAddons(modifiedServices, services[i].addons, args.serviceType)
						}
	
						index = helperObj[services[i].masterProdId];

						if (index) {
							// master prod present in resp

							if (!resp[index - 1].prodVariations) {
								resp[index - 1].prodVariations = [];
							}
							resp[index - 1].prodVariations.push(Object.assign({id:`${services[i].siteId}::${services[i].slugName}`}, services[i]))

						} else {
							// master prod not present in resp
							let ind = services.findIndex(obj => obj.serviceStatus && obj.slugName == services[i].masterProdId);

							if (ind != -1) {

								let obj = Object.assign({id:`${services[ind].siteId}::${services[ind].slugName}`}, services[ind]);

								if (!obj.prodVariations) {
									obj.prodVariations = [];
								}

								obj.prodVariations.push(Object.assign({id:`${services[i].siteId}::${services[i].slugName}`}, services[i]))
								
								resp.push(obj);
								helperObj[obj.slugName] = resp.length;
								// services.splice(ind, 1);

							}
						}

					} // else serviceRates eligible
				} // else serviceRates loop
			} // else serviceRates check
			// services.splice(i, 1);

		} // variation else
	} // services loop

	// console.log("result ::",services.length, resp.length)
	// console.log("result ::", helperObj)

	// console.timeEnd()

    // resolve(resp)
	// console.log("resp ::",resp)

    return resp
		
	} catch (error) {
		console.log("In getServicesByServiceType catch ::", error);
		// reject(error)
	}
// })

}



const queryFunction = (query, client, variables, fetchPolicy) => {
    return new Promise((resolve, reject) => {
      client.query({
        query: query,
        variables: variables,
        fetchPolicy: fetchPolicy
      }).then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          resolve([])
  
          console.log(`Catch: handleFormSubmit: error: ${err}`)
        })
    })
  }

export const getServicesByTypesCustom = (siteId, serviceTypes, client) => {
    return new Promise(async(resolve, reject) => {
    let nextToken = '', response = new Array();
        do{
            // let tempData = await queryFunction(getServiceBySiteIdPaginatedForCustom, client, {siteId: siteId, limit: 999, nextToken}, "network-only")
			let tempData = await queryFunction(getServiceBySiteIdPaginatedWithtag, client, {siteId: siteId, limit: 999, nextToken}, "network-only")
            if(tempData && tempData["getServicesBySiteIdPaginatedV2"] && tempData["getServicesBySiteIdPaginatedV2"].items){
              response = [...tempData["getServicesBySiteIdPaginatedV2"].items, ...response]
              // console.log("response ::", response)
              nextToken = tempData["getServicesBySiteIdPaginatedV2"].nextToken;
              nextToken = nextToken;
            } else {
              nextToken = null
            }
          } while(nextToken != null)
        //   console.timeEnd()

        let results = [],tmpServices, modiedData;

		let obj = {}
      
    
	for (let i = 0; i < serviceTypes.length; i++) {
        tmpServices = getServicesByServiceType({"siteId": siteId, "serviceType": serviceTypes[i].slugName }, response);
        //  console.log(`${serviceTypes[i].slugName} : ${tmpServices.length}`,tmpServices)

		 obj[serviceTypes[i].slugName] = tmpServices
        
        results.push(tmpServices)
    }
        resolve({data:obj , source:"productList" })
    })
}


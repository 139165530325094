const createObjectWithArray = (array, identifier) => {
    let iterator,
      responseObject = new Object();
    for (let i = 0; i < array.length; i++) {
      iterator = array[i];
      responseObject[iterator[identifier]] = iterator;
    }
    return responseObject;
  };

  const removeDuplicatesByKey = (array, key) => Object.values(array.reduce((acc, obj) => (acc[obj[key]] = obj, acc), {}));

  export {
    createObjectWithArray,
    removeDuplicatesByKey
  };
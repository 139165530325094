import gql from 'graphql-tag';
export default gql`
subscription onDayClosureAction( $siteId: String!){
    onDayClosureAction(siteId: $siteId) {
      id
      siteId
      operationId
      opType
      opDate
      performedBy
      timestamp
    }
  }
  
  # {
  #   "siteId": "a4784f08-1afd-4bee-bab4-d04faad09eb7"
  # }
  `
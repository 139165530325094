import gql from 'graphql-tag'

export default gql`
query getHotelCounterByCounterId($counterId: String! ){ 
    getHotelCounterByCounterId(counterId: $counterId ) { 
      siteId 
      typeValue 
      site 
      counterNo 
      counterName 
      isDayClosureEnabled
      counterStatus 
      updatedBy 
      updatedAt
      counterOperationMap{
        counterSiteId,
        operationId,
        deviceId 
        opType,
        date
      } 
    } 
  } 
`
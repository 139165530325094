import React, { Suspense, lazy } from 'react';
import GetSite from '../../queries/getSite'
import compose from 'lodash/flowRight';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Auth } from "@aws-amplify/auth";
import { SelectCurrencyContext, itemsData } from '../../context/CurrencyContext'
import { AuthContext } from '../../context/authContext';
import getJewelSiteSetting from '../../queries/getJewelSiteSetting'
import getRecahoAppVersion from '../../queries/getRecahoAppVersion'
import getPlatformServiceBySlugName from '../../queries/getPlatformServiceBySlugName';
import { SettingContext } from '../../context/SettingContext';
import { TableContext } from '../../context/tableContext';
// import WaiterApp from './waiterApp'
// import KitchenApp from './kitchenApp'
import KitchenAppStation from './kitchenAllStationApp'
import { Button, Spin, message } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
import getCustomLabels from '../../utils/getCustomLables';
import getSiteSubRoleByUserName from '../../queries/getSiteSubRoleByUserName';
import getServiceTypes from '../../queries/getServiceTypes';
import { getServiceTypeSettingInBulk } from '../../utils/getServiceTypeSettingInBulk';
import { gerenricQueryFunction } from '../../utils/genericQueryFunction';
import getCategoryBySiteIdV2 from '../../queries/getCategoryBySiteIdV2';
import getHotelStaffByUserRole from '../../queries/getHotelStaffByUserRole';
import GetPlatForm from '../../queries/getPlatformServicesByAdmin';
const WaiterApp = lazy(() => import('./waiterApp'))
const KitchenApp = lazy(() => import('./kitchenApp'))
import { AndroidPostMsg } from '../../utils/AndroidPostMsg';
import batchGetDiningTables from '../../queries/batchGetDiningTables';
import { getServicesByTypesCustom } from '../dineQueries/getServicesByTypesCustom';
import getServiceTags from '../../queries/getServiceTags';
import onTableUpdated from '../../subscription/onTableUpdated';
import getHotelStationsAlias from '../../queries/getHotelStationsAlias';
import { validateSelectedCounterOperationId } from '../../utils/counterValidation/validateSelectedCounterOperationId';
import { getDayCloseData } from '../../utils/DayClose/getDayCloseData';
import StoreClosedUi from './storeClosedUi';
import onCounterSessionInvalidate from '../../subscription/onCounterSessionInvalidate';
import onDayClosureAction from '../../subscription/onDayClosureAction';
import { createObjectWithArray } from '../../utils/basicLoops';
import moment from 'moment'
var axios = require('axios')

const LoadingComponent = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            flexDirection: "column",
            backgroundColor: "#f0f2f5"  // Light background color for contrast
        }}>
            <div style={{
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "#ffffff",  // White background for the message box
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  // Subtle shadow for a professional look
                maxWidth: "80%",  // Responsive width
            }}>
                <h3 style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#333",
                    marginBottom: "5px",
                }}>
                    Downloading App Settings
                </h3>
                <Spin size="small" style={{ marginTop: "10px" }} />
            </div>
        </div>
    )
}

const Icon = (props) => {
    if (props.type == 'ExclamationCircleFilled') {
        return (
            <ExclamationCircleFilled {...props} />
        )
    } else {
        return null
    }
}

const analyticUrl = process.env.ANALYTIC_URL;

class MainApp extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            siteType: '',
            authorized: false,
            curr: '',
            currency: itemsData.curr,
            domain: '',
            siteOption: '',
            siteSettings: {},
            platform: "mobile",
            loading: true,
            role: null,
            permissions: null,
            allSettings: null,
            menuTypes: null,
            categoryData: null,
            userData: null,
            platformConfig: null,
            tableData: [],
            productList:[],
            TopSellingItems: [],
            productLoading:true,
            waiterList: [],
            allServiceTags:[],
            specialTags: [],
            stationList:[],
            stationColorConfig:null,
            isStoreOpen: true,
            dayClosureData:null
        }
    }
    
    static getDerivedStateFromProps(nextProps, nextState) {
        if (nextProps.data.getSite === undefined || nextProps.data.getSite == null) {
            return null
        }
        //TODO - version mapper

        if (nextProps.data.getSite != undefined && nextProps.data.getSite !== null &&
            (nextProps.data.getSite.siteType === 'O2OCommerce' || nextProps.data.getSite.siteType === 'MARKETING')) {
            return {
                siteType: nextProps.data.getSite.siteType,
                currency: nextProps.data.getSite.basicSiteSetting.curr,
                siteOption: nextProps.data.getSite.basicSiteSetting.siteOptions,
                domain: nextProps.data.getSite.domain,
            }
        }
    }

    async componentDidMount() {


        let uname;

        let loader = document.getElementById('siteloader1')
        if (loader) {
            loader.remove()
        }

        await Auth.currentUserInfo()
            .then((data) => {
                AndroidPostMsg("USER_ROLE_CHECK")
                let Device = this.checkDevice()
                uname = data.username
                let siteId = data.attributes['custom:siteId']
                if (Device == 'Android OS') {
                    let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${uname}.deviceKey`)
                    let data = {
                        deviceId: deviceId,
                        siteId: siteId,
                        username: uname
                    }
                    let obj = {
                        "eventName": "LOGIN",
                        "payload": JSON.stringify(data)
                    }
                    window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                }

                if (Device == 'iOS') {
                    let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${uname}.deviceKey`)
                    let data = {
                        deviceId: deviceId,
                        siteId: siteId,
                        username: uname
                    }
                    let obj = {
                        "eventName": "LOGIN",
                        "payload": JSON.stringify(data)
                    }
                    window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                }

                if (data.attributes['custom:role'] == undefined) {
                    localStorage.setItem('role', 'Mer_Admin')
                    this.setState({
                        role: undefined
                    })
                } else {
                    localStorage.setItem('role', data.attributes['custom:role'])
                    this.setState({
                        role: data.attributes['custom:role']
                    })
                }
                localStorage.setItem('ph_no', data.attributes['phone_number'])
                localStorage.setItem('siteId', data.attributes['custom:siteId'])
                localStorage.setItem('domain', data.attributes['custom:fqdnMut'])

            })
            .catch(err => console.log(err));

        this.getPlatform()
        this.setState({ loading: true });

        let InitialQuery = [this.getPermissions(uname), this.getMenu()]
        let resolvedPromices = await Promise.all(InitialQuery);
        let UserData;
        let menuTypesList;
        if (resolvedPromices && resolvedPromices.length > 0) {
            for (let i = 0; i < resolvedPromices.length; i++) {
                if (resolvedPromices[i]) {
                    if (resolvedPromices[i].source == "getSiteSubRoleByUserName") {
                        UserData = resolvedPromices[i].data
                        if (UserData) {
                            const permissions = UserData?.permissions;
                            this.setState({
                                permissions: permissions,
                                userData: UserData,
                            });
                        }
                    }

                    if (resolvedPromices[i].source == "getServiceTypes") {
                        menuTypesList = resolvedPromices[i].data
                        this.setState({ menuTypes: resolvedPromices[i].data });
                    }
                }
            }
        }
        const counterData = await validateSelectedCounterOperationId(UserData , this.props.client)
         
      
        this.callAllQueries(UserData, menuTypesList, counterData)

        if(UserData?.userRole == "WAITER"){
            this.onTableStatusUpdated(UserData?.siteId)
        }

        try {
            let r = await this.registerPushNotif();
        } catch (error) {

        }
    }

    componentWillUnmount() {
        if (this.subscriptionCounterSessionInvalidate) {
            this.subscriptionCounterSessionInvalidate.unsubscribe()
        }

        if(this.subscriptionOnDayClosureAction){
            this.subscriptionOnDayClosureAction.unsubscribe()
        }

        if(this.subscriptionRoot){
            this.subscriptionRoot.unsubscribe()
        }
    }


    subScribeCounterSubscriptionOnSelect = (counterData) => {
        if (this.subscriptionCounterSessionInvalidate) {
            this.subscriptionCounterSessionInvalidate.unsubscribe()
        }

        this.counterSessionSubscription(counterData)
    }
   
    onDayClosureActionSubscription = (siteId) => {
      
        let retryAttempts = 0
        let payload = {
            siteId: siteId ? siteId: localStorage.getItem('siteId') ,
        }

        try {
            this.subscriptionOnDayClosureAction= this.props.client.subscribe({
                query: onDayClosureAction,
                variables: payload
            }).
                subscribe({
                    next: ({ data }) => {
                        const { onDayClosureAction } = data
                        retryAttempts = 0;
                        console.log("onDayClosureAction:::",onDayClosureAction)
                        if(onDayClosureAction?.opType == "CLOSED"){
                            this.setState({ isStoreOpen: false })
                        }
                        
                    },
                    error: (err) => {
                        retryAttempts++;
                        console.log('onDayClosureActionSubscription mainApp error', err);
                        const maxRetryAttempts = 5;

                        if (retryAttempts <= maxRetryAttempts) {
                            const delay = Math.pow(2, retryAttempts) * 1000;

                            setTimeout(() => this.onDayClosureActionSubscription(siteId), delay);

                        } else {
                            console.log('Max retry attempts reached. Stopping retries.');
                        }
                    }
                })
        } catch (err) {
            console.log('errrr subscription::', err)
        }

       
    }
    
    counterSessionSubscription = (Counter) => {
      
        let retryAttempts = 0
        let payload = {
            counterSiteId: `${Counter.site}::${Counter.typeValue}`,
        }
        console.log("counterSessionSubscription payload:::",payload)
        try {
            this.subscriptionCounterSessionInvalidate = this.props.client.subscribe({
                query: onCounterSessionInvalidate,
                variables: payload
            }).
                subscribe({
                    next: ({ data }) => {
                        const { onCounterSessionInvalidate } = data
                        retryAttempts = 0;
                        console.log("onCounterSessionInvalidate:::",onCounterSessionInvalidate)
                      
                       
                        if( onCounterSessionInvalidate.action == "COUNTER_CLOSE"){
                            localStorage.removeItem('SelectedCounter')
                            window.location.replace(window.location.origin);


                        }
                       
                    },
                    error: (err) => {
                        retryAttempts++;
                        console.log('onCounterSessionInvalidate mainApp error', err);
                        const maxRetryAttempts = 5;

                        if (retryAttempts <= maxRetryAttempts) {
                            const delay = Math.pow(2, retryAttempts) * 1000;

                            setTimeout(() => this.counterSessionSubscription(Counter), delay);

                        } else {
                            console.log('Max retry attempts reached. Stopping retries.');
                        }
                    }
                })
        } catch (err) {
            console.log('errrr subscription::', err)
        }

       
    }

    onTableStatusUpdated = (siteId) => {
        // let that = this;
        let retryAttempts = 0;
       
        try {
          this.subscriptionRoot = this.props.client.subscribe({
            query: onTableUpdated,
            variables: {
              siteId: siteId ? siteId: localStorage.getItem('siteId') 
            }
          }).
            subscribe({
              next: ({ data }) => {
                const { onTableUpdated } = data
                retryAttempts = 0;
                  if(onTableUpdated && onTableUpdated.isRefreshMenu){
                    // console.log("onTableUpdated ff:::",onTableUpdated)
                    message.success("Menu List Updating....")
                    this.syncUpdatedMenu()
                  }
                try {
                  let index = this.state.tableData.findIndex((x) => x.typeValue == onTableUpdated.typeValue)
                  if (index > -1) {
                    let arr = JSON.parse(JSON.stringify(this.state.tableData))
                    arr[index].tableStatus = onTableUpdated.tableStatus
                    this.setState({tableData:arr})
                  }
                } catch (error) {
                  console.log("error::", error)
                }
              },
              error: (err) => {
                retryAttempts++;
                console.log('table updated v2 mainApp error', err);
                const maxRetryAttempts = 5;
    
                if (retryAttempts <= maxRetryAttempts) {
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
    
                  setTimeout(() => this.onTableStatusUpdated(siteId), delay);
    
                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
              }
            })
        } catch (err) {
          console.log('errrr subscription::', err)
    
        }
    
      }

    syncUpdatedMenu = async() => {
        let siteId = localStorage.getItem('siteId') 
        this.setState({productLoading:true})
        let requiredOrdersQuery = [getServicesByTypesCustom(siteId, this.state.menuTypes, this.props.client), this.getCategory("network-only")]

        let requiredResolvedPromises= await Promise.all(requiredOrdersQuery);

        if(requiredResolvedPromises && requiredResolvedPromises.length > 0){
            for (let i = 0; i < requiredResolvedPromises.length; i++) {
                if (requiredResolvedPromises[i]) {
    
                    if (requiredResolvedPromises[i].source == 'categoryData') {
                        this.setState({ categoryData: requiredResolvedPromises[i].data });
                    }
    
                    if (requiredResolvedPromises[i].source == "productList") {
                        this.setState({productList:requiredResolvedPromises[i].data })
                    }
    
                   
                }
            }
           } 
           this.setState({productLoading: false})
       
    }  

    callDealyQuery = async (arr) => {
        let resolvedPromices = await Promise.all(arr);

        if (resolvedPromices && resolvedPromices.length > 0) {
            for (let i = 0; i < resolvedPromices.length; i++) {
                if (resolvedPromices[i]) {
                    if (resolvedPromices[i].source == "categoryData") {
                        this.setState({ categoryData: resolvedPromices[i].data });
                    }

                    if (resolvedPromices[i].source == "getPlatformServicesByAdmin") {
                        this.setState({ platformConfig: resolvedPromices[i].data });
                    }

                    if(resolvedPromices[i].source == 'getServiceTags'){
                        this.setState({allServiceTags: resolvedPromices[i].data , specialTags : resolvedPromices[i].specialTags})
                    }
                }
            }
        }
        // console.log("callDealyQuery::::", resolvedPromices)
    }

    callAllQueries = async (UserData, menuTypesList, counterData) => {
          
        let callPromise = []
        let DelayQuery = []
        if (UserData) {
            callPromise.push(this.getDinningTable(UserData?.assignedTables))
        }

        callPromise.push(this.getAppVersion(), this.getSiteSettings(), this.getAllTypeSetting(menuTypesList), this.getPlatformServiceBySlugName(), this.getStation() )

        let resolvedPromices = await Promise.all(callPromise);
        //  console.log("callAllQueries:::", resolvedPromices)

        if (resolvedPromices && resolvedPromices.length > 0) {
            for (let i = 0; i < resolvedPromices.length; i++) {
                if (resolvedPromices[i]) {

                    if (resolvedPromices[i].source == 'batchGetDiningTables') {
                        this.setState({ tableData: resolvedPromices[i].data })
                    }

                    if (resolvedPromices[i].source == "getAllTypeSetting") {
                        this.setState({ allSettings: resolvedPromices[i].data })
                    }

                    if (resolvedPromices[i].source == "getSiteSubRoleByUserName") {
                        const permissions = resolvedPromices[i].data?.permissions;

                        this.setState({
                            permissions: permissions,
                            userData: resolvedPromices[i].data,
                        });
                    }

                    if(resolvedPromices[i].source == 'getHotelStations'){
                        let stationList = resolvedPromices[i].data
                        if(stationList && stationList.length > 0){
                            const transformedData = stationList.reduce((acc, station) => {    
                                if (station.trackerConfig) {
                                  const value = {
                                    red: 0,
                                    green: 0,
                                    orange: 0
                                  };
                                  station.trackerConfig.forEach(config => {
                                    if (config.color === 'red' || config.color === 'green' || config.color === 'orange') {
                                      value[config.color] = config.time;
                                    }
                                  });
                                  acc[station.typeValue] = value;
                                }  
                                return acc;
                              }, {});

                              stationList = createObjectWithArray(stationList, "typeValue");
                             this.setState({stationList: stationList, stationColorConfig:transformedData})
                        }
                    }

                    if (resolvedPromices[i].source == "categoryData") {
                        this.setState({ categoryData: resolvedPromices[i].data });
                    }

                    if (resolvedPromices[i].source == "getPlatformServicesByAdmin") {
                        this.setState({ platformConfig: resolvedPromices[i].data });
                    }

                    if (resolvedPromices[i].source == "getSiteSettings") {

                        if(counterData && resolvedPromices[i].data?.isCounterEnabled){
                            this.counterSessionSubscription(counterData)
                        }
                        if(resolvedPromices[i].data?.isManualDayClosure){
                            this.onDayClosureActionSubscription(UserData?.siteId)
                            this.getManualClosuresData()
                        }
                        this.setState({ siteSettings: { ...resolvedPromices[i].data , groupId: this.props.data?.getSite?.groupId }});
                    }

                    if (resolvedPromices[i].source == "getServiceTypes") {
                        this.setState({ menuTypes: resolvedPromices[i].data });
                    }

                    if (resolvedPromices[i].source == "getPlatformServiceBySlugName") {
                        this.setState({ authorized: resolvedPromices[i].data });
                    }
                }
            }
        }

        this.setState({ loading: false })
    
        if (UserData?.userRole == "WAITER") {
            let requiredOrdersQuery = [getServicesByTypesCustom(UserData.siteId, menuTypesList, this.props.client), this.getCategory(), this.getWaiterList(), this.getTopSellingItems(UserData.siteId)]

            let requiredResolvedPromises = await Promise.all(requiredOrdersQuery);
          
            if (requiredResolvedPromises && requiredResolvedPromises.length > 0) {
                for (let i = 0; i < requiredResolvedPromises.length; i++) {
                    if (requiredResolvedPromises[i]) {

                        if (requiredResolvedPromises[i].source == 'categoryData') {
                            this.setState({ categoryData: requiredResolvedPromises[i].data });
                        }

                        if (requiredResolvedPromises[i].source == "productList") {
                            this.setState({ productList: requiredResolvedPromises[i].data, productLoading: false })
                        }

                        if (requiredResolvedPromises[i].source == "waiterList") {
                            this.setState({ waiterList: requiredResolvedPromises[i].data });
                        }

                        if (requiredResolvedPromises[i].source == "TopSellingItems") {
                            this.setState({ TopSellingItems: requiredResolvedPromises[i].data });
                        }
                    }
                }
            }

        }
         
       
        if (UserData) {
            if (UserData?.userRole == "WAITER") {
                DelayQuery.push(this.GetPlatForm(),this.getTags())
                this.callDealyQuery(DelayQuery)
            }
        }
    }

    getManualClosuresData = async () => {
        const dayClosedData = await getDayCloseData(this.props.client)

        if (dayClosedData && dayClosedData.items && dayClosedData.items.length > 0) {
            let updatedDayClosureData = { ...dayClosedData.items[0] }
            if (updatedDayClosureData.opType == "OPEN") {
                this.setState({ isStoreOpen: true,dayClosureData:updatedDayClosureData })
            } else {
                this.setState({ isStoreOpen: false ,dayClosureData:updatedDayClosureData })
            }
        } else {
            this.setState({ isStoreOpen: false })
        }
      
    }

    getAppVersion = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: getRecahoAppVersion,
            })
                .then(({ data }) => {
                    if (data.getRecahoAppVersion != null) {
                        this.setAppVersion(data.getRecahoAppVersion)
                        resolve({ data: data.getRecahoAppVersion, source: "getAppVersion" });
                    }
                })
                .catch((err) => {
                    console.error(`Catch: getAppVersion: error: ${JSON.stringify(err, null, 2)}`);
                    reject(err); // Reject the promise in case of error
                });
        });
    };

    checkDevice = () => {
        var Name = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") != -1) Name = "Windows OS";
        if (navigator.userAgent.indexOf("Mac") != -1) Name = "Macintosh";
        if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux OS";
        if (navigator.userAgent.indexOf("Android") != -1) Name = "Android OS";
        if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";
        return Name
    }

    getPlatform = () => {
        if (window.screen.width >= 700) {
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }
    }

    getPermissions = async (username) => {
        return new Promise((resolve, reject) => {
            this.setState({ loading: true });
            // console.log("getPermissions::::::", username)
            this.props.client.query({
                query: getSiteSubRoleByUserName,
                variables: {
                    userName: username
                }
            })
                .then(({ data }) => {
                    if (data && data.getSiteSubRoleByUserName) {

                        resolve({ source: "getSiteSubRoleByUserName", data: data.getSiteSubRoleByUserName });

                    } else {
                        resolve(null); // Handle case where data is null
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    console.error(`Error in getPermissions: ${JSON.stringify(err)}`);
                    reject(null);
                });
        });
    };

    getWaiterList = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: getHotelStaffByUserRole,
                variables: {
                    userRole: "WAITER",
                    nextToken: ''
                },
                fetchPolicy: 'network-only'
            })
            .then(({ data }) => {
                if (data.getHotelStaffByUserRole != null) {
                   
                   resolve({ data: data.getHotelStaffByUserRole.items, source: 'waiterList' });
                    
                } else {
                    resolve({ data: [], source: 'waiterList' });  // In case no data is returned
                }
            })
            .catch(err => {
                console.log(`Catch: waiterList: error: ${JSON.stringify(err, null, 2)}`);
                reject(null);
            });
        });
    };

    getTags = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: getServiceTags,
                fetchPolicy: 'network-only'
            })
            .then(({ data }) => {
                if (data.getServiceTags != null) {
                    let alltags = data.getServiceTags.length > 0 ? data.getServiceTags.slice(0, 3) : []
                     let specialTagArray = []
                    alltags && alltags.length > 0 && alltags.map((item) => {
                        if (item.tagName == "tag4" && specialTagArray.length == 0) {
                            specialTagArray = item.tagOptions
                        }
                    })
                    resolve({ data: alltags, source: 'getServiceTags' , specialTags: specialTagArray });
                } else {
                    resolve({ data: [], source: 'getServiceTags', specialTags: [] }); // In case no tags are returned
                }
            })
            .catch(err => {
                console.log(`Catch: getTags: error: ${JSON.stringify(err, null, 2)}`);
                reject(err);
            });
        });
    };
     
    getTopSellingItems = (siteId) => {
        return new Promise((resolve, reject) => {
            const currentMonthIndex = moment().month();
            const currentYear = moment().year();
            const todayDate = moment().date();
            const currentDate = moment();
            const previousMonth = currentDate.clone().subtract(1, 'months');
           const previousMonthIndex = previousMonth.month() === 11 ? 12 : previousMonth.month() + 1;
           const previousMonthYear = previousMonth.year();
            let params = {
                "siteId": siteId,
                "year": todayDate <= 5 ? previousMonthYear : currentYear,
                "month": todayDate <= 5 ? previousMonthIndex  : currentMonthIndex+1 ,
                // "month":12,
                // "day":18,
                "serviceId": 'abc'
            }
            axios({
                method: 'post',
                url: `https://${analyticUrl}.mysnaptrade.com/getMenuSalesDaywise`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: params
            })
                .then(function (response) {
                      
                    let data = []

                    if (response.data.data) {
                        data = response.data.data
                    }
                  
                    resolve({ data: data, source: 'TopSellingItems' });
                })
                .catch(function (error) {
                    console.log("In Error get profile:", error);
                    resolve({ data: [], source: 'TopSellingItems' });
    
                });
        })
    
    }

    getCategory = (fetchPolicy) => {
        return new Promise(async (resolve, reject) => {
            let  policy = fetchPolicy ? "network-only" : "cache-first"
            try {
                const response = await gerenricQueryFunction(getCategoryBySiteIdV2, this.props.client, { limit: 1000 }, policy , "getCategoryBySiteIdV2");

                if (response) {
                    if (response.isError) {
                        message.error("Something Went Wrong");
                        reject(null);
                    } else if (response.data) {
                        resolve({ source: "categoryData", data: response.data });
                    } else {
                        message.error('');
                        reject(null);
                    }
                } else {
                    reject(null);
                }
            } catch (error) {
                message.error("Something Went Wrong");
                reject(null);
            }
        });
    };

    divideArrayIntoBatches(array, batchSize) {
        const batches = [];
        for (let i = 0; i < array.length; i += batchSize) {
            batches.push(array.slice(i, i + batchSize));
        }
        return batches;
    }

    getTables = (tables) => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: batchGetDiningTables,
                variables: {
                    tableIds: tables
                },
                fetchPolicy: 'network-only',
            })
                .then(({ data }) => {
                    resolve(data.batchGetDiningTables || [])
                }
                ).catch(err => {
                    console.log(`Catch: handleFormSubmit: error:`, err);
                })
        })
    }

    getDinningTable = (assignedTables) => {
        return new Promise(async (resolve, reject) => {
            try {
                let tables = assignedTables ? assignedTables : [];
                const batchSize = 95;
                const batches = this.divideArrayIntoBatches(tables, batchSize);
                let arr = [], temp;
                for (let i = 0; i < batches.length; i++) {
                    temp = await this.getTables(batches[i]);
                    arr = [...arr, ...temp];
                }
                resolve({ data: arr, source: "batchGetDiningTables" });
            } catch (error) {
                reject(null);
            }
        });
    };

    GetPlatForm = () => {
        return new Promise((resolve, reject) => {
            this.props.client
                .query({
                    query: GetPlatForm,
                    fetchPolicy: "network-only",
                })
                .then(({ data }) => {
                    if (data && data.getPlatformServicesByAdmin) {
                        resolve({ source: "getPlatformServicesByAdmin", data: data.getPlatformServicesByAdmin });
                    } else {
                        this.setState({ platformConfig: {} });
                        resolve({ source: "getPlatformServicesByAdmin", data: {} });
                    }

                });
        });
    };

    getSiteSettings = () => {
        return new Promise((resolve, reject) => {
            this.setState({ loading: true });
            this.props.client.query({
                query: getJewelSiteSetting,
            })
                .then(({ data }) => {
                    if (data.getJewelSiteSetting) {
                        let settingObj = { ...data.getJewelSiteSetting }
                        let labelsObj = getCustomLabels(data.getJewelSiteSetting.customLabelsMap ? data.getJewelSiteSetting.customLabelsMap.customLabels : null)
                        settingObj.customLabels = labelsObj

                        localStorage.setItem('inventory', data.getJewelSiteSetting.inventory);
                        resolve({ data: settingObj, source: "getSiteSettings" });
                    } else {
                        reject(null);
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    console.error(`Error in getSiteSettings: ${err}`);
                    reject(null); // Reject the promise in case of an error
                });
        });
    };


    getMenu = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: getServiceTypes,
                fetchPolicy: 'cache-first',
            })
                .then(({ data }) => {
                    if (data.getServiceTypes) {
                        let menu = data.getServiceTypes
                        let menuArr = []
                        menu && menu.length > 0 && menu.map((m) => {
                            if (m.typeStatus != false && (m.masterServiceType == 'dine-in' || m.slugName == 'dine-in')) {
                                menuArr.push(m)
                            }
                        })

                        // this.getAllTypeSetting(menuArr);

                        resolve({ data: menuArr, source: 'getServiceTypes' });

                    } else {
                        reject(null);
                    }
                })
                .catch((err) => {
                    console.error(`Error in getMenu: ${JSON.stringify(err)}`);
                    reject(null);
                });
        });
    };


    getAllTypeSetting = (allData) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (allData.length > 0) {
                    let obj = {};
                    let serviceTypeArr = [];

                    allData.forEach((type) => {
                        serviceTypeArr.push(type.slugName);
                    });

                    const settingResp = await getServiceTypeSettingInBulk(serviceTypeArr, this.props.client);

                    if (settingResp && settingResp.length > 0) {
                        settingResp.forEach((setting) => {
                            if (setting && setting.typeValue) {
                                obj[setting.typeValue.split('::')[0]] = setting;
                            }
                        });
                    }
                    resolve({ data: obj, source: 'getAllTypeSetting' });
                } else {
                    resolve({ data: {}, source: 'getAllTypeSetting' });
                }
            } catch (err) {
                console.log("Error in getAllTypeSetting:", err);
                reject(null);
            }
        });
    };

    getStation = () => {
        return new Promise((resolve,reject)=>{
        this.props.client.query({
            query: getHotelStationsAlias,
            fetchPolicy: 'no-cache',
          
        })
            .then(({ data }) => {
            if(data.getHotelStations){
                resolve({ data: data.getHotelStations, source: 'getHotelStations' });
            }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
        })
    }

    delay = ms => new Promise(res => setTimeout(res, ms));
    setAppVersion = async (data) => {
        let staffVersion = localStorage.getItem('staffVersion')
        if (data.staffVersion != staffVersion) {
            message.success("App Updating...", 3)
            localStorage.setItem('staffVersion', data.staffVersion)
            // await this.delay(3000);
            if (window && window.location) {
                // window.location.href = window.location.pathname + '?refresh=true';
                // window.location.reload();
                window.location.replace(window.location.href);
            }
        }
        return data
    }

    registerPushNotif = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                var tok = localStorage.getItem('PNToken')
                if (tok == null) {
                    tok = await getToken()
                    localStorage.setItem('PNToken', tok)
                    let p = await this.tokenUpdate(tok)
                    resolve(p)
                } else {
                    resolve(tok)
                }
            } catch (error) {
                reject(error)
            }
        })
    }

    tokenUpdate = (token) => {
        return new Promise(async (resolve, reject) => {

            this.props.SubscribeTokenToOrderPNAlias({
                variables: {
                    token: token
                }
            }).then((res) => {
                resolve(true)

            }).catch((err) => {
                console.log('token error :', err)
                resolve(false)

            })
        })

    }

    reloadApp = () => {
        if (window && window.location) {
            // window.location.href = window.location.pathname + '?refresh=true';
            // window.location.reload();
            window.location.replace(window.location.href);
        }
    }

    handleSignOut = async () => {
        let flag = confirm("Are you sure you want to Logout??")
        if (flag) {
            await Auth.currentUserInfo()
                .then((userData) => {
                    let Device = this.checkDevice()
                    if (Device == 'Android OS') {
                        let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${userData.username}.deviceKey`)
                        let data = {
                            deviceId: deviceId,
                            siteId: localStorage.getItem('siteId'),
                            username: userData.username
                        }
                        let obj = {
                            "eventName": "LOGOUT",
                            "payload": JSON.stringify(data)
                        }
                        window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                    }

                    if (Device == 'iOS') {
                        let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${userData.username}.deviceKey`)
                        let data = {
                            deviceId: deviceId,
                            siteId: localStorage.getItem('siteId'),
                            username: userData.username
                        }
                        let obj = {
                            "eventName": "LOGOUT",
                            "payload": JSON.stringify(data)
                        }
                        window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                    }
                })
            localStorage.removeItem('inventory')
            localStorage.removeItem('siteOp')
            localStorage.removeItem("siteId")
            localStorage.removeItem("role")
           
            document.cookie = 'appVersion=v1; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'version=v1; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            Auth.signOut()
                .then(() => {
                    let origin = window.location.origin

                    if (origin == "https://www.mysnaptrade.com") {
                        window.location = "https://www.mysnaptrade.com/staff/"
                    } else {
                        window.location = window.location.origin;
                    }
                    localStorage.clear()
                })
                .catch(err => console.log(err));
        } else {
            return
        }
    };

    getPlatformServiceBySlugName = () => {
        return new Promise((resolve, reject) => {
            this.setState({ loading: true });

            this.props.client.query({
                query: getPlatformServiceBySlugName,
                variables: {
                    siteId: localStorage.getItem('siteId'),
                    slugServiceName: localStorage.getItem('role') === 'WAITER' ? 'captain-app' : 'chef-app'
                },
                fetchPolicy: 'network-only',
            })
                .then(({ data }) => {
                    if (data && data.getPlatformServiceBySlugName && data.getPlatformServiceBySlugName.status) {
                        resolve({ data: true, source: "getPlatformServiceBySlugName" });
                    } else {
                        resolve({ data: false, source: "getPlatformServiceBySlugName" });
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    console.error(`Error in getPlatformServiceBySlugName: ${JSON.stringify(err)}`);
                    reject(null);
                });
        });
    };

    render() {
        let role = this.state.role
        localStorage.setItem('siteType', this.state.siteType);
        localStorage.setItem('siteOp', this.state.siteOption);
        
        if (this.state.loading) {
            return <LoadingComponent />
        }
        if (!this.state.authorized && this.state.loading == false) {
            return <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: this.state.platform == 'mobile' ? "50%" : "10%", maxHeight: "100vh" }}>
                    <div style={{ padding: '10px', fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>

                        <p>Access Denied <Icon type="ExclamationCircleFilled" style={{ color: "red" }} size='sm' /> </p>
                        <p>Please ensure you have the necessary permissions or have purchased the required services to log in to our app.</p>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button type="primary" onClick={this.handleSignOut}>LogOut</Button>
                </div>
            </>
        }

        if (!this.state.isStoreOpen) {
            return <>
                <StoreClosedUi isStoreOpen={this.state.isStoreOpen} handleSignOut={this.handleSignOut} />
            </>
        }


        if (role === "WAITER" || role === "CAPTAIN" || role === "CHEF") {
            return (
                <>
                    {(this.state.loading == true || Object.keys(this.state.siteSettings).length == 0) ? <LoadingComponent /> :
                        <Suspense fallback={<LoadingComponent />}>
                            <SelectCurrencyContext.Provider
                                value={{
                                    curr: this.state.currency,
                                    siteType: this.state.siteType,
                                    siteOption: this.state.siteOption
                                }}
                            >
                                <SettingContext.Provider value={{ settingData: this.state.siteSettings, ...this.state.siteSettings, platform: this.state.platform, userData: this.state.userData, permissions: this.state.permissions, allSettings: this.state.allSettings, categoryData: this.state.categoryData, userRole: role, platformConfig: this.state.platformConfig, loading: this.state.loading }}>
                                    <TableContext.Provider value={{ allTables: this.state.tableData, categoryData: this.state.categoryData , productList: this.state.productList , TopSellingItems: this.state.TopSellingItems, productLoading: this.state.productLoading ,waiterList: this.state.waiterList, allServiceTags: this.state.allServiceTags, specialTags: this.state.specialTags, stationColorConfig: this.state.stationColorConfig, subScribeCounterSubscriptionOnSelect: this.subScribeCounterSubscriptionOnSelect, dayClosureData: this.state.dayClosureData , stationList: this.state.stationList}}>
                                        {role === undefined && role === 'admin' ?
                                            <Button >LogOut</Button>
                                            : ''}
                                        {role === "WAITER" || role === "CAPTAIN" ?
                                            <WaiterApp {...this.props} />
                                            :
                                            role === "CHEF" ?
                                                <KitchenApp {...this.props} />
                                                :
                                                role === 'Mer_Admin' ?
                                                    <KitchenAppStation {...this.props} />
                                                    :
                                                    <Button onClick={this.reloadApp}>Reload</Button>
                                        }
                                    </TableContext.Provider>
                                </SettingContext.Provider>
                            </SelectCurrencyContext.Provider>
                        </Suspense>
                    }
                </>
            );
        } else {
            return (
                <>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                        <div>
                            <p style={{ padding: '10px', fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>
                                You are attempting to log in to the Captain App using ADMIN credentials.
                                If you wish to log in as an admin, please visit &nbsp; <a href="https://www.mysnaptrade.com" target="_blank">mysnaptrade.com</a> &nbsp;
                                or download the Recaho Admin app from the Play Store.</p>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button type="primary" onClick={this.handleSignOut}>LogOut</Button>
                    </div>
                </>


            )
        }
    }
}


MainApp.contextType = AuthContext;

const WrapApp = compose(
    // withRouter,
    withApollo,
    graphql(GetSite, {
        options: props => ({
            fetchPolicy: "network-only"
        })
    })

)(MainApp)
export default WrapApp
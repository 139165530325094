import gql from 'graphql-tag';

export default gql`
    query getSite{
        getSite{
            id
            domain
            fqdn
            siteType
            groupId
            basicSiteSetting {
                curr
                siteOptions
                appVersion
            }

        }
    }
`
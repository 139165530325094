import getDayClosures from "../../queries/getDayClosures";

const getDayCloseData = (client) => {
    return new Promise((resolve, reject) => {
        client.query({
            query: getDayClosures,
            fetchPolicy: 'no-cache',
            variables: {
                limit: 1,
                nextToken: null
              }
        })
            .then(({ data }) => {
                if (data.getDayClosures) {
                    resolve(data.getDayClosures);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`);
                resolve(null);
            });
    });
};


export{
    getDayCloseData 
}
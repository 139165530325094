import gql from 'graphql-tag'

export default gql`
query getDayClosures($limit: Int $nextToken: String){
  getDayClosures(limit: $limit nextToken: $nextToken) {
    items {
       id
      siteId
      operationId
      opType
      opDate
      performedBy
      timestamp
    }
    nextToken
  }
}


# {
#   "limit": 1,
#   "nextToken": null
# }`

import gql from 'graphql-tag'

export default gql`
query{
    getHotelStations{
      typeValue
      restrictItemCompletion
      trackerConfig {
        time
        color
      }
    }
  }
`



import gql from 'graphql-tag'
export default gql `

query getJewelSiteSetting{
    getJewelSiteSetting{
      homePageTitle
      homePageMetadesc
      facebookLink
      paymentModes{
        internalName
        displayName
      }
      customLabelsMap{
        domain
          typeValue
          customLabels{
            defaultName
            displayName
          }
      }
      twitterLink
      instaLink
      contactNumber
      companyWhatsApp
      languages
      htmlPrint
      companyProfile
      contactEmail
      companyName
      supportNumber
      disableSharedTable
      integratePaymentOption
      backgroundColor
      isOfflineEnabled
      isCounterEnabled
      menuFontColor
      aggregatorMerchantId
      isAggregatorEnabled
      facebookPageId
      facebookAppId
      headerDesign
      siteOptions
      taxMethod
      noOfKot
      landingPage
      headerDesignWeb
      headerDesignMob
      footerDesign
      footerDesignWeb
      footerDesignMob
      feedbackMsg
      inventory
      isOld
      pin
      ipAddress
      printerOnMobile
      cashDrawer
      macAddress
      physicalAddress
      connectedVia
        printerSize
        printers{
          uniqId
          type
          label
          value
          paperSize
          physicalAddress
          connectedVia
        }
        packagingSlipPrinters{
          uniqId
          type
          label
          value
          paperSize
        }
        billPrinters{
          uniqId
          type
          label
          value
          paperSize
          physicalAddress
          connectedVia
          serverName
          platform
        }
      printServer{
        name
        macAddress
        available
      }
      showInventory
      expenseCreditCategories
      expenseDebitCategories
      gallery
      isManualDayClosure
      isDayClosureEnabled
      closureTime
      diningSettingMenu
      galleryAsTag
      whatsAppText
      gstNo
      fssai
      vatNo
      paymentLink
      availableWNumbers
      availableWhatsApp
      mapLink
      mapUrl
      menuDesign
      customerName
      designation
      paymentModes{
        internalName
        displayName
        paymentState
      }
      invoice
      festivalBanners
      bookingDelay
      siteMode
      fixfooter
      menuDisplay
      askUserInfo
      askUserInfoRequired
      chooseLangOpen
      siteModeStaticPage
      festivalSubBanner{
        bucket
        region
        key
      }

      customerImage{
        bucket
        region
        key
      }
      curr
      siteId
      enabledOutstandingOnPay
      expenseCategories
      orderText
      orderButtonColor
      integratePaymentOption
      reasonReject
      diningSetup
      themeDesign
      showOnlyMenu
      showMenu
      frontSite
      footerFields
      minOrderValue
      googleAppId
      googleTagId
      twitterTagId
      linkedInInsightId
      googleTagManagerId
      facebookPixelId
      appointmentType
      appointmentName
      quantityReq
      bookingHomePage
      availableWhatsApp
      availableWNumbers
     isMultipleMenu
     seletedMenu
      # groupId
      bookingStaticPageWeb
      bookingStaticPageMob
      orderResp
      isWalletEnabled
      isWebAppEnabled
      siteLogo{
        bucket
        region
        key
      }
      siteImages{
        bucket
        region
        key
      }
      qrcode{
        bucket
        region
        key
      }
      favIcon{
        bucket
        region
        key
      }
      textFontType
      textFontColor
      addressOne
      addressTwo
      footerAddress{
        addressLineOne
      addressLineTwo
      city
      state
      zip
      country
      location{
        lat
        long
      }
      }
      latlng{
        lat
        lng
    }
    isCustomWhatsapp
    allowCustomUserData
    customFeilds{
      customType
      customName
      customValue
      customListValue
      menuAttached
      required
      length
    }
    availableFeilds{
      availableType
  displayName
  display
  menuAttached
  required
    }
    customCharges{
      customChargeName
      customChargeList{
        name
        rate
        chargeType
        defaultSelcted
        tax

      }
    }
    htmlCode
    discountOnReport
    isCounterEnabled
    isCustOnGroupEnabled
    customerSegments{
      name
      discountType
      discountAmt
      # desc
    }
    disabledPoweredBy
    showBillPrintedOnCaptain
    fontTheme
  }
}

`





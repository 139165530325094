import getHotelCounterByCounterId from "../../queries/getHotelCounterByCounterId"
const validateSelectedCounterOperationId =  (UserData, client) => {

   return new Promise(async(resolve, reject) => {

        if (localStorage.hasOwnProperty('SelectedCounter') && UserData?.userRole === "WAITER") {
            let selectedCounterData = JSON.parse(localStorage.getItem('SelectedCounter'));
            const counterDetails = await getCounterDetailsByCounterId(client, selectedCounterData.typeValue);
            if (counterDetails && counterDetails.counterStatus == "LOCKED") {
                selectedCounterData.operationId = counterDetails?.counterOperationMap?.operationId
                localStorage.setItem("SelectedCounter",JSON.stringify(selectedCounterData))
                resolve(selectedCounterData); 
            } else {
                localStorage.removeItem('SelectedCounter')
                resolve(null);
            }
           
        } else {
            resolve(null); 
        }
    })
    
};

const getCounterDetailsByCounterId = (client, counterId) => {
    return new Promise((resolve, reject) => {
        client.query({
            query: getHotelCounterByCounterId,
            fetchPolicy: 'no-cache',
            variables: {
                counterId: counterId,
            },
        })
            .then(({ data }) => {
                if (data.getHotelCounterByCounterId) {
                    resolve(data.getHotelCounterByCounterId);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`);
                resolve(null);
            });
    });
};


export {
    validateSelectedCounterOperationId
}
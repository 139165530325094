import gql from 'graphql-tag';
export default gql`
subscription onCounterSessionInvalidate(
    $counterSiteId: String!
  ) {
    onCounterSessionInvalidate(counterSiteId: $counterSiteId ){
      counterSiteId
      deviceId
      action
    }
  }
`
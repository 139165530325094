import React from 'react';

const storeClosedUi = (props) => {
    const {isStoreOpen, handleSignOut} = props
    return (
        <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            
        }}
    >
        <div
            style={{
                textAlign: 'center',
                padding: '10px',
                borderRadius: '15px',
               
                color: '#2d3748' ,
                boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
                fontFamily: `'Roboto', sans-serif`,
            }}
        >
            <h1
                style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    color: '#2d3748' 
                }}
            >
                { 'ACTION REQUIRED: DAY NOT OPENED'}
            </h1>
            <p
                style={{
                    fontSize: '1.4rem',
                    lineHeight: '1.6',
                    marginBottom: '30px',
                    color: isStoreOpen ? '#4a5568' : '#a0aec0',
                }}
            >
                You cannot take orders as the current day is not opened in the system.
            </p>
            <p style={{
                    fontSize: '1.4rem',
                    lineHeight: '1.6',
                    marginBottom: '30px',
                    color: isStoreOpen ? '#4a5568' : '#a0aec0',
                }}>
            Please contact the Admin to open the day on the Recaho POS to continue.
            </p>
            <div
                style={{
                    display: 'inline-block',
                    padding: '10px 20px',
                    backgroundColor: isStoreOpen ? '#4caf50' : '#e53e3e',
                    color: '#fff',
                    borderRadius: '30px',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    textTransform: 'uppercase',
                    cursor: isStoreOpen ? 'pointer' : 'not-allowed',
                    transition: 'background-color 0.3s ease',
                }}
                onClick={handleSignOut}
            >
                { 'logout'}
            </div>
        </div>
    </div>
    
    );
};

export default storeClosedUi;
